<template>
  <div>
    <CCard>
      <CCardBody class="text-center">
        <h4>Thank you for taking this exam</h4>
        <p>Results will be sent to assessment agency</p>
        <CButton color="primary" @click="logout">Logout</CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { localDb } from "@/mixins/localDb";
export default {
  name: "ExamEnd",
  mixins: [localDb],
  methods: {
    logout() {
      this.remove("examData", "local");
      window.close();
      this.$router.push("/auth/login");
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === "Login") next();
      else next(false);
    },
  },
};
</script>

<style scoped>
</style>